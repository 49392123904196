require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/a30b5b55f22741613a559969b4fe6af3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a30b5b55f22741613a559969b4fe6af3'] = async function fn_a30b5b55f22741613a559969b4fe6af3() {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'\''].join(''))).setPageSize(10)))[0])
}

})
define('./functions/f45b50c2979c3da63cf1d8468d759773/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f45b50c2979c3da63cf1d8468d759773'] = async function fn_f45b50c2979c3da63cf1d8468d759773() {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return ((await Backendless.Data.of('Users').loadRelations((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('forumId').setPageSize(10).setOffset(0)))[0])
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/5percent/components/0caa44a37a3dcbe17276aab2110e4065/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'updateDate')) != null) {
    try {
      ___arguments.context.dataModel['disableSubmit'] = true;
      ___arguments.context.pageData['createdUpdate'] = (await Backendless.Data.of('updates').save( ({ 'businessFeelings': (getObjectProperty(___arguments.context.dataModel, 'businessFeelings')),'businessImpact': (getObjectProperty(___arguments.context.dataModel, 'businessImpact')),'businessSignificance': (getObjectProperty(___arguments.context.dataModel, 'businessSignificance')),'familyFeelings': (getObjectProperty(___arguments.context.dataModel, 'familyFeelings')),'familyImpact': (getObjectProperty(___arguments.context.dataModel, 'familyImpact')),'familySignificance': (getObjectProperty(___arguments.context.dataModel, 'familySignificance')),'next3060': (getObjectProperty(___arguments.context.dataModel, 'next3060')),'oneWordOpen': (getObjectProperty(___arguments.context.dataModel, 'oneWordOpen')),'personalFeelings': (getObjectProperty(___arguments.context.dataModel, 'personalFeelings')),'personalImpact': (getObjectProperty(___arguments.context.dataModel, 'personalImpact')),'personalSignificance': (getObjectProperty(___arguments.context.dataModel, 'personalSignificance')),'ratingBusiness': (getObjectProperty(___arguments.context.dataModel, 'ratingBusiness')),'ratingFamily': (getObjectProperty(___arguments.context.dataModel, 'ratingFamily')),'ratingPersonal': (getObjectProperty(___arguments.context.dataModel, 'ratingPersonal')),'updateDate': (getObjectProperty(___arguments.context.dataModel, 'updateDate')) }) ));
      await Backendless.Data.of('updates').addRelation((getObjectProperty(___arguments.context.pageData, 'createdUpdate')), 'forum', [(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'forumDetails')), 'objectId'))]);
      await Backendless.Data.of('updates').addRelation((getObjectProperty(___arguments.context.pageData, 'createdUpdate')), 'memberName', [(getObjectProperty(___arguments.context.pageData, 'userObjectId'))]);
      await new Promise(r => setTimeout(r, 500 || 0));
      ___arguments.context.dataModel['disableSubmit'] = false;

    } catch (error) {
      console.log(error);

    } finally {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('goalUpdate', undefined);

    }
  } else {
    ___arguments.context.dataModel['errorMessage'] = 'Please select the next forum date in the date picker at the top.';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('17aa3f270712b2c3e41bd37e399bedf3', true);
  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/5percent/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var forumDetails, userDetails;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  if ((await Backendless.UserService.getCurrentUser(false)) == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else {
    userDetails = (await BackendlessUI.Functions.Custom['fn_a30b5b55f22741613a559969b4fe6af3']());
    forumDetails = (await BackendlessUI.Functions.Custom['fn_f45b50c2979c3da63cf1d8468d759773']());
    ___arguments.context.pageData['forumId'] = (getObjectProperty(forumDetails, 'forumId'));
    ___arguments.context.pageData['firstName'] = (textToTitleCase(getObjectProperty(userDetails, 'firstName')));
    ___arguments.context.pageData['pageLogo'] = (getObjectProperty(forumDetails, 'logo'));
    ___arguments.context.pageData['forumName'] = (getObjectProperty(forumDetails, 'name'));
    ___arguments.context.pageData['forumDetails'] = forumDetails;
    ___arguments.context.pageData['userObjectId'] = (getObjectProperty(userDetails, 'objectId'));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin/components/344aa91b9f7624739a7423d62afa526a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setRelationsDepth(3).setPageSize(100)));
  ___arguments.context.pageData['testData'] = (getObjectProperty(((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setRelationsDepth(3).setPageSize(100)))[1]), 'forumId.name'));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/admin/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var forumDetails, userDetails;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  if ((await Backendless.UserService.getCurrentUser(false)) == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else {
    userDetails = (await BackendlessUI.Functions.Custom['fn_a30b5b55f22741613a559969b4fe6af3']());
    forumDetails = (await BackendlessUI.Functions.Custom['fn_f45b50c2979c3da63cf1d8468d759773']());
    ___arguments.context.pageData['forumDetails'] = (await BackendlessUI.Functions.Custom['fn_f45b50c2979c3da63cf1d8468d759773']());
    ___arguments.context.pageData['forumId'] = (getObjectProperty(forumDetails, 'forumId'));
    ___arguments.context.pageData['forumMonthlyRepeat'] = (getObjectProperty(forumDetails, 'forumMonthlyRepeat'));
    ___arguments.context.pageData['forumDayOfWeek'] = (getObjectProperty(forumDetails, 'forumDayOfWeek'));
    ___arguments.context.pageData['firstName'] = (textToTitleCase(getObjectProperty(userDetails, 'firstName')));
    ___arguments.context.pageData['pageLogo'] = (getObjectProperty(forumDetails, 'logo'));
    ___arguments.context.pageData['forumName'] = (getObjectProperty(forumDetails, 'name'));
    ___arguments.context.pageData['fineGoal'] = (getObjectProperty(forumDetails, 'fineGoal'));
    ___arguments.context.pageData['fineLate'] = (getObjectProperty(forumDetails, 'fineLate'));
    ___arguments.context.pageData['fineNoShow'] = (getObjectProperty(forumDetails, 'fineNoShow'));
    ___arguments.context.pageData['finePhone'] = (getObjectProperty(forumDetails, 'finePhone'));
    ___arguments.context.pageData['forumDayOfMonth'] = (getObjectProperty(forumDetails, 'forumDayOfMonth'));
    ___arguments.context.pageData['forumName'] = (getObjectProperty(forumDetails, 'name'));
    ___arguments.context.pageData['reminderDays'] = (getObjectProperty(forumDetails, 'reminderDays'));
    ___arguments.context.pageData['updateReminder'] = (getObjectProperty(forumDetails, 'updateReminder'));
    ___arguments.context.pageData['retreatBudget'] = (getObjectProperty(forumDetails, 'retreatBudget'));
    ___arguments.context.pageData['readOnly'] = true;
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin/components/748aca66a793fcce5e52586c7ba9c525/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('$') + String(getObjectProperty(___arguments.context.pageData, 'fineGoal')))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin/components/cae184632d9d7d33beb865469b2880f3/bundle.js', [], () => ({
  /* content */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['forum/',(getObjectProperty(___arguments.context.pageData, 'forumId')),'/'].join(''))

  },
  /* handler:onDirectoryAssignment */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['newLogo'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['pageLogo'] = (getObjectProperty((await Backendless.Data.of('forum').save( ({ 'objectId': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'forumDetails')), 'objectId')),'logo': (getObjectProperty(___arguments.context.pageData, 'newLogo')) }) )), 'logo'));
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cae184632d9d7d33beb865469b2880f3');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/admin/components/a9c3b32a6a8730a7b805db83fc3fa22d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'readOnly'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin/components/9e9e965bf1afc1bc19df559382dd7bff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['readOnly'] = (!(getObjectProperty(___arguments.context.pageData, 'readOnly')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin/components/0a1b8304515815741f831d11bae9ac23/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}



  return (['Forum meetings are on the: ',textToTitleCase(String(getObjectProperty(___arguments.context.pageData, 'forumMonthlyRepeat'))),textToTitleCase(String(' ') + String(getObjectProperty(___arguments.context.pageData, 'forumDayOfWeek'))),' of the month'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin/components/294dc1ff940b63f47a9918857573861a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}



  return (textToTitleCase(String(getObjectProperty(___arguments.context.pageData, 'forumDayOfWeek'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboard/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var forumDetails, userDetails;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  if ((await Backendless.UserService.getCurrentUser(false)) == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else {
    userDetails = (await BackendlessUI.Functions.Custom['fn_a30b5b55f22741613a559969b4fe6af3']());
    forumDetails = (await BackendlessUI.Functions.Custom['fn_f45b50c2979c3da63cf1d8468d759773']());
    ___arguments.context.pageData['forumId'] = (getObjectProperty(forumDetails, 'forumId'));
    ___arguments.context.pageData['firstName'] = (textToTitleCase(getObjectProperty(userDetails, 'firstName')));
    ___arguments.context.pageData['pageLogo'] = (getObjectProperty(forumDetails, 'logo'));
    ___arguments.context.pageData['forumName'] = (getObjectProperty(forumDetails, 'name'));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/dashboard/components/ca54d2eed12a6ff24ed1d486939a39e2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['forumId.forumId=\'',(getObjectProperty(___arguments.context.pageData, 'forumId')),'\''].join('')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboard/components/83492a8941dd64418919d3795f32ad4b/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var finesList, userObjectId;


  finesList = (await Backendless.Data.of('fines').find(Backendless.DataQueryBuilder.create().setWhereClause((['user.objectId=\'',(Backendless.getCurrentUserToken()),'\''].join(''))).setPageSize(100)));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/8ebbfffb2a48dd03f76635d4a1ca516c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var userDetails;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await Backendless.Data.of('goals').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['completed=false and ownerId=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'\''].join('')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboard/components/ddb640c43e16607c5f64d0e88807c463/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await Backendless.Data.of('goals').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['completed=true and ownerId=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'\''].join('')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboard/components/8338325bbdb19de85918865431289c2b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('5percent', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/4afb4fcce95521a3424de57644e29e17/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('goals', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/c8c87b082482d99f726f882d39b9c551/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('goalUpdate', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/goalUpdate/components/bc5e7a49460f699f973d9b787685791a/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('bd935a86e76ee5024acf2208ccaef9df', (await Backendless.Data.of('goals').find(Backendless.DataQueryBuilder.create().setPageSize(30))));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/goalUpdate/components/e46290199aad93a870afd2134c78f7aa/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5c64fbf94ede4645bc926dbdb7658fe1'), 'dueDate')))).toLocaleDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/goalUpdate/components/2f6aced91f998388ad53d4d8e74b5475/bundle.js', [], () => ({
  /* content */
  /* handler:onWhereClauseAssignment */
  async ['onWhereClauseAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['member=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'\' and completed=false'].join(''))

  },
  /* handler:onWhereClauseAssignment */
  /* content */
}))

define('./pages/goalUpdate/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var forumDetails, userDetails;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  if ((await Backendless.UserService.getCurrentUser(false)) == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else {
    userDetails = (await BackendlessUI.Functions.Custom['fn_a30b5b55f22741613a559969b4fe6af3']());
    forumDetails = (await BackendlessUI.Functions.Custom['fn_f45b50c2979c3da63cf1d8468d759773']());
    ___arguments.context.pageData['forumId'] = (getObjectProperty(forumDetails, 'forumId'));
    ___arguments.context.pageData['firstName'] = (textToTitleCase(getObjectProperty(userDetails, 'firstName')));
    ___arguments.context.pageData['pageLogo'] = (getObjectProperty(forumDetails, 'logo'));
    ___arguments.context.pageData['forumName'] = (getObjectProperty(forumDetails, 'name'));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/goalUpdate/components/ab7a59565628ee29f42c122f1208e01b/bundle.js', [], () => ({
  /* content */
  /* handler:onMinDateAssignment */
  ['onMinDateAssignment'](___arguments) {
      return (new Date((new Date()).getTime() + ((25 * 86400) * 1000)))

  },
  /* handler:onMinDateAssignment */
  /* content */
}))

define('./pages/goalUpdate/components/4dba72e3498f31c2e258ba5a13c2e878/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['savedGoal'] = (await Backendless.Data.of('goals').save( ({ 'description': (getObjectProperty(___arguments.context.pageData, 'goalDescription')),'dueDate': (getObjectProperty(___arguments.context.pageData, 'goalDate')) }) ));
  await Backendless.Data.of('goals').setRelation((getObjectProperty(___arguments.context.pageData, 'savedGoal')), 'member', [(await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'\''].join(''))).setPageSize(10)))[0]]);
  ___arguments.context.pageData['goalDescription'] = '';
  ___arguments.context.pageData['goalDate'] = '';
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reloadData() })('2f6aced91f998388ad53d4d8e74b5475');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/goalUpdate/components/cbd7096ab87a868760310a7bb11885dc/bundle.js', [], () => ({
  /* content */
  /* handler:onWhereClauseAssignment */
  async ['onWhereClauseAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['member=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'\' and completed=true'].join(''))

  },
  /* handler:onWhereClauseAssignment */
  /* content */
}))

define('./pages/goals/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var forumDetails, userDetails;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  if ((await Backendless.UserService.getCurrentUser(false)) == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else {
    userDetails = (await BackendlessUI.Functions.Custom['fn_a30b5b55f22741613a559969b4fe6af3']());
    forumDetails = (await BackendlessUI.Functions.Custom['fn_f45b50c2979c3da63cf1d8468d759773']());
    ___arguments.context.pageData['forumId'] = (getObjectProperty(forumDetails, 'forumId'));
    ___arguments.context.pageData['fineAmount'] = (getObjectProperty(forumDetails, 'fineGoal'));
    ___arguments.context.pageData['firstName'] = (textToTitleCase(getObjectProperty(userDetails, 'firstName')));
    ___arguments.context.pageData['pageLogo'] = (getObjectProperty(forumDetails, 'logo'));
    ___arguments.context.pageData['forumName'] = (getObjectProperty(forumDetails, 'name'));
    ___arguments.context.pageData['forumDetails'] = forumDetails;
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('2349e564790e1a3ae31a4e2f973a8f65', (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['forumId.forumId=\'',(getObjectProperty(forumDetails, 'forumId')),'\''].join(''))).setRelationsDepth(2).setSortBy('name').setPageSize(20))));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/goals/components/b8a3c737b5c71c5c7344a40d752a294d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await Backendless.Data.of('goals').save( ___arguments.context.getComponentDataStoreByUid('3ba932b49aba62ceef027a1e791b220e') );

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/goals/components/82bb80fd33c1e9241a945b0d505d55e9/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('82bb80fd33c1e9241a945b0d505d55e9', (await Backendless.Data.of('goals').find(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f42797b3ead47e3461bb562e91d33de9'), 'objectId')),'\''].join(''))).setSortBy('`dueDateOnly` asc,`description` asc').setPageSize(100))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/goals/components/933a2e1107120c72df4d4c41f9c7ea2b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('953e92583aa5ac351c02a67fb0a82957');

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/goals/components/953e92583aa5ac351c02a67fb0a82957/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('goals').save( ___arguments.context.getComponentDataStoreByUid('3ba932b49aba62ceef027a1e791b220e') );
  ___arguments.context.getComponentDataStoreByUid('3ba932b49aba62ceef027a1e791b220e')['fineApplied'] = true;
  ___arguments.context.pageData['createdFine'] = (await Backendless.Data.of('fines').save( ({ 'reason': String('Missed Goal - ') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('3ba932b49aba62ceef027a1e791b220e'), 'description')),'amount': (getObjectProperty(___arguments.context.pageData, 'fineAmount')),'dueDate': (new Date((new Date()).getTime() + ((30 * 86400) * 1000))) }) ));
  await Backendless.Data.of('fines').setRelation((getObjectProperty(___arguments.context.pageData, 'createdFine')), 'fineItem', [(await Backendless.Data.of('goals').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('3ba932b49aba62ceef027a1e791b220e'), 'objectId')),'\''].join(''))).setPageSize(10)))[0]]);
  await Backendless.Data.of('fines').setRelation((getObjectProperty(___arguments.context.pageData, 'createdFine')), 'member', [(await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('3ba932b49aba62ceef027a1e791b220e'), 'ownerId')),'\''].join(''))).setPageSize(10)))[0]]);
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('953e92583aa5ac351c02a67fb0a82957');

  },
  /* handler:onSubmit */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('3ba932b49aba62ceef027a1e791b220e')['fineApplied'] = false;
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('953e92583aa5ac351c02a67fb0a82957');

  },
  /* handler:onClose */
  /* handler:contentLogic */
  ['contentLogic'](___arguments) {
    
  },
  /* handler:contentLogic */
  /* content */
}))

define('./pages/login/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var user, error, password, username, stayLoggedIn, logging, buttonLogin;

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, stayLoggedIn  ));
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', undefined);

  } catch (error) {
    await setError(error);

  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  stayLoggedIn = (getObjectProperty(___arguments.context.dataModel, 'stayLoggedIn'));
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (user) {
    ___arguments.context.appData['currentUser'] = user;
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/login/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if ((await Backendless.UserService.isValidLogin()) == true) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/passwordRecovery/components/4641c8cb6f0597610bb5b1fce8828044/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.UserService.restorePassword((getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'emailInput')));

  },
  /* handler:onClick *//* content */
}));

define('./pages/registration/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await Backendless.UserService.getCurrentUser(false)) != null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', undefined);
  }
  if (getObjectProperty(___arguments.context.pageData, 'forumId')) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6a9b2ead7d8cb2732dab4f1e40a804d3'))['forumId'] = (getObjectProperty(___arguments.context.pageData, 'forumId'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('37faaf0706486b222772c1ab0afaec7e', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7e1824505728be4f8053f1f35de435d', false);
  }
  if (getObjectProperty(___arguments.context.pageData, 'email')) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6a9b2ead7d8cb2732dab4f1e40a804d3'))['email'] = (getObjectProperty(___arguments.context.pageData, 'email'));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/registration/components/6a9b2ead7d8cb2732dab4f1e40a804d3/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var forum;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  forum = (await Backendless.Data.of('forum').find(Backendless.DataQueryBuilder.create().setWhereClause((['forumID = \'',(getObjectProperty(___arguments.context.dataModel, 'forumId')),'\''].join(''))).setPageSize(1)));
  if (!!forum.length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('556797fb0ef5f38e795b8078c8b1273b', true);
    if ((getObjectProperty(___arguments.context.dataModel, 'password')) != (getObjectProperty(___arguments.context.dataModel, 'passRepeat'))) {
      ___arguments.context.dataModel['passwordError'] = 'Passwords do not match.';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('556797fb0ef5f38e795b8078c8b1273b', true);
    } else {
      await Backendless.Data.of('Users').addRelation((await Backendless.UserService.register( new Backendless.User({ 'firstName': (getObjectProperty(___arguments.context.dataModel, 'firstName')),'lastName': (getObjectProperty(___arguments.context.dataModel, 'lastName')),'name': [(getObjectProperty(___arguments.context.dataModel, 'firstName')),' ',(getObjectProperty(___arguments.context.dataModel, 'lastName'))].join(''),'email': (getObjectProperty(___arguments.context.dataModel, 'email')),'password': (getObjectProperty(___arguments.context.dataModel, 'password')) }) )), 'forumId', forum);
      await Backendless.UserService.login( (getObjectProperty(___arguments.context.dataModel, 'email')), (getObjectProperty(___arguments.context.dataModel, 'password')), true  );
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', undefined);
    }
  } else {
    ___arguments.context.dataModel['errorMessage'] = 'Forum code does not exist. Please enter a correct forum code';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('28268d7ddf0adccc632d224c5f8bf13a', true);
  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/registration/components/442ffc5140c16f6eb989cb9e9a11ddde/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/test/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var forumDetails, userDetails;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  userDetails = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((String('objectId=\'CDD9C4FA-1FBD-4358-9EEE-085168420664\''))).setPageSize(10)))[0];
  forumDetails = (await Backendless.Data.of('Users').loadRelations('CDD9C4FA-1FBD-4358-9EEE-085168420664', Backendless.LoadRelationsQueryBuilder.create().setRelationName('forumId').setPageSize(10).setOffset(0)))[0];
  ___arguments.context.pageData['forumId'] = (getObjectProperty(forumDetails, 'forumId'));
  ___arguments.context.pageData['firstName'] = (textToTitleCase(getObjectProperty(userDetails, 'firstName')));
  ___arguments.context.pageData['pageLogo'] = (getObjectProperty(forumDetails, 'logo'));
  ___arguments.context.pageData['forumName'] = (getObjectProperty(forumDetails, 'name'));
  ___arguments.context.pageData['forumDetails'] = forumDetails;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('2349e564790e1a3ae31a4e2f973a8f65', (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['forumId.forumId=\'',(getObjectProperty(forumDetails, 'forumId')),'\''].join(''))).setRelationsDepth(2).setPageSize(20))));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/test/components/82bb80fd33c1e9241a945b0d505d55e9/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  async ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await Backendless.Data.of('goals').find(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f42797b3ead47e3461bb562e91d33de9'), 'objectId')),'\''].join(''))).setSortBy('dueDate and description').setPageSize(20)))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/test/components/b8a3c737b5c71c5c7344a40d752a294d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('3ba932b49aba62ceef027a1e791b220e'));
  await Backendless.Data.of('goals').save( ___arguments.context.getComponentDataStoreByUid('3ba932b49aba62ceef027a1e791b220e') );

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/updatesList/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var forumDetails, userDetails;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  if ((await Backendless.UserService.getCurrentUser(false)) == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else {
    userDetails = (await BackendlessUI.Functions.Custom['fn_a30b5b55f22741613a559969b4fe6af3']());
    forumDetails = (await BackendlessUI.Functions.Custom['fn_f45b50c2979c3da63cf1d8468d759773']());
    ___arguments.context.pageData['forumId'] = (getObjectProperty(forumDetails, 'forumId'));
    ___arguments.context.pageData['firstName'] = (textToTitleCase(getObjectProperty(userDetails, 'firstName')));
    ___arguments.context.pageData['pageLogo'] = (getObjectProperty(forumDetails, 'logo'));
    ___arguments.context.pageData['forumName'] = (getObjectProperty(forumDetails, 'name'));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3c824b4775f7b38e0fd8899db4d58a4d', (await Backendless.Data.of('updates').find(Backendless.DataQueryBuilder.create().setWhereClause((['forum.forumId=\'',(getObjectProperty(___arguments.context.pageData, 'forumId')),'\''].join(''))).setRelationsDepth(2).setSortBy('updateDate').setGroupBy('updateDateFriendly').setPageSize(10))));
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/updatesList/components/6a9a35429f3ecb7cb865b4b38316ca6d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('updates', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/updatesList/components/8f2256eb567ef9d78aaae82d2fb83973/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  async ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await Backendless.Data.of('updates').find(Backendless.DataQueryBuilder.create().setWhereClause((['forum.forumId=\'',(getObjectProperty(___arguments.context.pageData, 'forumId')),'\' and updateDateFriendly=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('1875fafacdad9e03c0cd5753c77f976e'), 'updateDateFriendly')),'\''].join(''))).setRelationsDepth(2).setSortBy('memberName.name').setPageSize(10)))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/updatesList/components/512786610044cdf54db525299ef97d0c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('viewUpdate', ({ 'id': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cf34bf2163144395649b49dba1fa925f'), 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/updatesOld/components/0caa44a37a3dcbe17276aab2110e4065/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'updateDate')) != null) {
    try {
      ___arguments.context.dataModel['disableSubmit'] = true;
      ___arguments.context.pageData['createdUpdate'] = (await Backendless.Data.of('updates').save( ({ 'businessFeelings': (getObjectProperty(___arguments.context.dataModel, 'businessFeelings')),'businessImpact': (getObjectProperty(___arguments.context.dataModel, 'businessImpact')),'businessNew': (getObjectProperty(___arguments.context.dataModel, 'businessNew')),'businessSignificance': (getObjectProperty(___arguments.context.dataModel, 'businessSignificance')),'familyFeelings': (getObjectProperty(___arguments.context.dataModel, 'familyFeelings')),'familyImpact': (getObjectProperty(___arguments.context.dataModel, 'familyImpact')),'familyNew': (getObjectProperty(___arguments.context.dataModel, 'familyNew')),'familySignificance': (getObjectProperty(___arguments.context.dataModel, 'familySignificance')),'next3060': (getObjectProperty(___arguments.context.dataModel, 'next3060')),'oneWordOpen': (getObjectProperty(___arguments.context.dataModel, 'oneWordOpen')),'personalFeelings': (getObjectProperty(___arguments.context.dataModel, 'personalFeelings')),'personalImpact': (getObjectProperty(___arguments.context.dataModel, 'personalImpact')),'personalNew': (getObjectProperty(___arguments.context.dataModel, 'personalNew')),'personalSignificance': (getObjectProperty(___arguments.context.dataModel, 'personalSignificance')),'ratingBusiness': (getObjectProperty(___arguments.context.dataModel, 'ratingBusiness')),'ratingFamily': (getObjectProperty(___arguments.context.dataModel, 'ratingFamily')),'ratingPersonal': (getObjectProperty(___arguments.context.dataModel, 'ratingPersonal')),'topicToPresent': (getObjectProperty(___arguments.context.dataModel, 'topicToPresent')),'topicUrgency': (getObjectProperty(___arguments.context.dataModel, 'topicUrgency')),'updateDate': (getObjectProperty(___arguments.context.dataModel, 'updateDate')) }) ));
      await Backendless.Data.of('updates').addRelation((getObjectProperty(___arguments.context.pageData, 'createdUpdate')), 'forum', [(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'forumDetails')), 'objectId'))]);
      await Backendless.Data.of('updates').addRelation((getObjectProperty(___arguments.context.pageData, 'createdUpdate')), 'memberName', [(getObjectProperty(___arguments.context.pageData, 'userObjectId'))]);
      await new Promise(r => setTimeout(r, 500 || 0));
      ___arguments.context.dataModel['disableSubmit'] = false;

    } catch (error) {
      console.log(error);

    } finally {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('goalUpdate', undefined);

    }
  } else {
    ___arguments.context.dataModel['errorMessage'] = 'Please select the next forum date in the date picker at the top.';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('17aa3f270712b2c3e41bd37e399bedf3', true);
  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/updatesOld/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var forumDetails, userDetails;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  if ((await Backendless.UserService.getCurrentUser(false)) == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else {
    userDetails = (await BackendlessUI.Functions.Custom['fn_a30b5b55f22741613a559969b4fe6af3']());
    forumDetails = (await BackendlessUI.Functions.Custom['fn_f45b50c2979c3da63cf1d8468d759773']());
    ___arguments.context.pageData['forumId'] = (getObjectProperty(forumDetails, 'forumId'));
    ___arguments.context.pageData['firstName'] = (textToTitleCase(getObjectProperty(userDetails, 'firstName')));
    ___arguments.context.pageData['pageLogo'] = (getObjectProperty(forumDetails, 'logo'));
    ___arguments.context.pageData['forumName'] = (getObjectProperty(forumDetails, 'name'));
    ___arguments.context.pageData['forumDetails'] = forumDetails;
    ___arguments.context.pageData['userObjectId'] = (getObjectProperty(userDetails, 'objectId'));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/viewUpdate/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var updateData, forumDetails, userDetails;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  if ((await Backendless.UserService.getCurrentUser(false)) == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else {
    userDetails = (await BackendlessUI.Functions.Custom['fn_a30b5b55f22741613a559969b4fe6af3']());
    forumDetails = (await BackendlessUI.Functions.Custom['fn_f45b50c2979c3da63cf1d8468d759773']());
    ___arguments.context.pageData['forumId'] = (getObjectProperty(forumDetails, 'forumId'));
    ___arguments.context.pageData['firstName'] = (textToTitleCase(getObjectProperty(userDetails, 'firstName')));
    ___arguments.context.pageData['pageLogo'] = (getObjectProperty(forumDetails, 'logo'));
    ___arguments.context.pageData['forumName'] = (getObjectProperty(forumDetails, 'name'));
    ___arguments.context.pageData['forumDetails'] = forumDetails;
  }
  if (getObjectProperty(___arguments.context.pageData, 'id')) {
    updateData = (await Backendless.Data.of('updates').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'id')),'\''].join(''))).setRelationsDepth(1).setPageSize(1)))[0];
    ___arguments.context.pageData['updateDateFriendly'] = (getObjectProperty(updateData, 'updateDateFriendly'));
    ___arguments.context.pageData['name'] = (getObjectProperty((
        await Backendless.Data.of( Backendless.User ).findById((getObjectProperty(updateData, 'ownerId')))
    ), 'firstName'));
    ___arguments.context.pageData['updateMonth'] = (getObjectProperty(updateData, 'updateMonth'));
    ___arguments.context.pageData['updateMonth'] = (getObjectProperty(updateData, 'updateMonth'));
    ___arguments.context.pageData['oneWordOpen'] = (getObjectProperty(updateData, 'oneWordOpen'));
    ___arguments.context.pageData['topicToPresent'] = (getObjectProperty(updateData, 'topicToPresent'));
    ___arguments.context.pageData['topicUrgency'] = (getObjectProperty(updateData, 'topicUrgency'));
    ___arguments.context.pageData['ratingBusiness'] = (getObjectProperty(updateData, 'ratingBusiness'));
    ___arguments.context.pageData['ratingFamily'] = (getObjectProperty(updateData, 'ratingFamily'));
    ___arguments.context.pageData['ratingPersonal'] = (getObjectProperty(updateData, 'ratingPersonal'));
    ___arguments.context.pageData['businessNew'] = (getObjectProperty(updateData, 'businessNew'));
    ___arguments.context.pageData['businessSignificance'] = (getObjectProperty(updateData, 'businessSignificance'));
    ___arguments.context.pageData['businessFeelings'] = (getObjectProperty(updateData, 'businessFeelings'));
    ___arguments.context.pageData['businessImpact'] = (getObjectProperty(updateData, 'businessImpact'));
    ___arguments.context.pageData['familyNew'] = (getObjectProperty(updateData, 'familyNew'));
    ___arguments.context.pageData['familyImpact'] = (getObjectProperty(updateData, 'familyImpact'));
    ___arguments.context.pageData['familySignificance'] = (getObjectProperty(updateData, 'familySignificance'));
    ___arguments.context.pageData['familyFeelings'] = (getObjectProperty(updateData, 'familyFeelings'));
    ___arguments.context.pageData['personalNew'] = (getObjectProperty(updateData, 'personalNew'));
    ___arguments.context.pageData['personalFeelings'] = (getObjectProperty(updateData, 'personalFeelings'));
    ___arguments.context.pageData['personalSignificance'] = (getObjectProperty(updateData, 'personalSignificance'));
    ___arguments.context.pageData['personalImpact'] = (getObjectProperty(updateData, 'personalImpact'));
    ___arguments.context.pageData['next3060'] = (getObjectProperty(updateData, 'next3060'));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/viewUpdatesOld/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var updateData, forumDetails, userDetails;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  if ((await Backendless.UserService.getCurrentUser(false)) == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else {
    userDetails = (await BackendlessUI.Functions.Custom['fn_a30b5b55f22741613a559969b4fe6af3']());
    forumDetails = (await BackendlessUI.Functions.Custom['fn_f45b50c2979c3da63cf1d8468d759773']());
    ___arguments.context.pageData['forumId'] = (getObjectProperty(forumDetails, 'forumId'));
    ___arguments.context.pageData['firstName'] = (textToTitleCase(getObjectProperty(userDetails, 'firstName')));
    ___arguments.context.pageData['pageLogo'] = (getObjectProperty(forumDetails, 'logo'));
    ___arguments.context.pageData['forumName'] = (getObjectProperty(forumDetails, 'name'));
    ___arguments.context.pageData['forumDetails'] = forumDetails;
  }
  if (getObjectProperty(___arguments.context.pageData, 'id')) {
    updateData = (await Backendless.Data.of('updates').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'id')),'\''].join(''))).setRelationsDepth(1).setPageSize(1)))[0];
    ___arguments.context.pageData['updateDateFriendly'] = (getObjectProperty(updateData, 'updateDateFriendly'));
    ___arguments.context.pageData['name'] = (getObjectProperty((
        await Backendless.Data.of( Backendless.User ).findById((getObjectProperty(updateData, 'ownerId')))
    ), 'firstName'));
    ___arguments.context.pageData['updateMonth'] = (getObjectProperty(updateData, 'updateMonth'));
    ___arguments.context.pageData['updateMonth'] = (getObjectProperty(updateData, 'updateMonth'));
    ___arguments.context.pageData['oneWordOpen'] = (getObjectProperty(updateData, 'oneWordOpen'));
    ___arguments.context.pageData['topicToPresent'] = (getObjectProperty(updateData, 'topicToPresent'));
    ___arguments.context.pageData['topicUrgency'] = (getObjectProperty(updateData, 'topicUrgency'));
    ___arguments.context.pageData['ratingBusiness'] = (getObjectProperty(updateData, 'ratingBusiness'));
    ___arguments.context.pageData['ratingFamily'] = (getObjectProperty(updateData, 'ratingFamily'));
    ___arguments.context.pageData['ratingPersonal'] = (getObjectProperty(updateData, 'ratingPersonal'));
    ___arguments.context.pageData['businessNew'] = (getObjectProperty(updateData, 'businessNew'));
    ___arguments.context.pageData['businessSignificance'] = (getObjectProperty(updateData, 'businessSignificance'));
    ___arguments.context.pageData['businessFeelings'] = (getObjectProperty(updateData, 'businessFeelings'));
    ___arguments.context.pageData['businessImpact'] = (getObjectProperty(updateData, 'businessImpact'));
    ___arguments.context.pageData['familyNew'] = (getObjectProperty(updateData, 'familyNew'));
    ___arguments.context.pageData['familyImpact'] = (getObjectProperty(updateData, 'familyImpact'));
    ___arguments.context.pageData['familySignificance'] = (getObjectProperty(updateData, 'familySignificance'));
    ___arguments.context.pageData['familyFeelings'] = (getObjectProperty(updateData, 'familyFeelings'));
    ___arguments.context.pageData['personalNew'] = (getObjectProperty(updateData, 'personalNew'));
    ___arguments.context.pageData['personalFeelings'] = (getObjectProperty(updateData, 'personalFeelings'));
    ___arguments.context.pageData['personalSignificance'] = (getObjectProperty(updateData, 'personalSignificance'));
    ___arguments.context.pageData['personalImpact'] = (getObjectProperty(updateData, 'personalImpact'));
    ___arguments.context.pageData['next3060'] = (getObjectProperty(updateData, 'next3060'));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./components/reusable/r_c65f924936d898fef18a423a9a4c068d/logic/055ea04a53154641f804ccd6e994eba7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var list;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('055ea04a53154641f804ccd6e994eba7')).slice(-1)[0] == 'active') {
    return (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('055ea04a53154641f804ccd6e994eba7')).pop() && ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3bd85c2358db23e22522cde5cf93368b')).pop());
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('055ea04a53154641f804ccd6e994eba7')), 'active');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3bd85c2358db23e22522cde5cf93368b')), 'show');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c65f924936d898fef18a423a9a4c068d/logic/82fe0551e69fd5fb00a4967fba849a0d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Hello ',(getObjectProperty(___arguments.context.pageData, 'firstName')),','].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_c65f924936d898fef18a423a9a4c068d/logic/5373219ce44bfc05536a8baa4b61b286/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Welcome to Forum Buddy for: ') + String(getObjectProperty(___arguments.context.pageData, 'forumName')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_c65f924936d898fef18a423a9a4c068d/logic/868c34d8dceab198871cd8cd503d912e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

